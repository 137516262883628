body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #aa06a0;
  color: #FFFFFF;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
div {
	box-sizing: border-box;
}

.player-wrapper {
  position: relative;
  /*padding-top: 56.25%  Player ratio: 100 / (1280 / 720) */
  padding-top: 147.1%;
  height: 0;
  background: transparent url('/flyer-2.jpg') left top no-repeat;
  background-size: cover;
}
 
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

div#outer {
    display: table;
    position: relative;
    height: 100%;
    width: 100%;
}
div#middle {
    display: table-cell;
    vertical-align: middle;
	padding: 20px 20px 10px 20px;
}
div#inner {
    margin-left: auto;
    margin-right: auto;
	max-width: 600px;
}
div.top {
	margin-bottom: 10px;
}
div#content img {
	width: 100%;
	max-width: 600px;
	height: auto;
}
div.tickets {
	max-height: 0px;
	overflow: hidden;
	transition: max-height 500ms linear;
}
div.tickets.ticketsOn {
	max-height: 90px;
}
div.fButtons,
div.tickets {
	display: flex;
	justify-content: space-between;
}
div.fButtons .menButton,
div.tickets a {
    background-color: #14b2a9;
    color: #FFF;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 36px;
    width: 23%;
	text-align: center;
	margin: 10px 0 0 0;
    display: block;
    font-weight: bold;
    border-radius: 6px;
    text-decoration: none;
}
div.tickets a.menButton {
    width: 48.5%;
    background-color: #ffdd43;
    font-size: 15px;
    color: #1475b2;
}
div.fButtons .menButton.menSocial {
	/* float: right;
	margin: 10px 0 0 1%; */
}

div.fText {
	clear: both;
    margin: 0 auto 20px;
    position: relative;
    max-width: 1200px;
    text-align: center;
    line-height: 150%;
	font-size: 12px;
}
span.sep,
span.sep1,
span.sep2 {
	display: inline-block;
	margin: 0 5px;
}
.blocky {
    white-space: nowrap;
}
.blocky a {
	color: #FFFFFF;
	text-decoration: none;
}

@media (max-width: 780px) {
	span.sep1 {
		clear: both;
		display: block;
		height: 0;
		font-size: 0;
	}
}
@media (max-width: 610px) {
	span.sep {
		clear: both;
		display: block;
		height: 0;
		font-size: 0;
	}
}
@media (max-width: 510px) {
	div.fButtons {
		flex-flow: row wrap;
	}
	div.fButtons .menButton {
		font-size: 18px;
		line-height: 36px;
		width: 48%;
	}
	div.tickets a.menButton {
		font-size: 16px;
		line-height: 28px;
		width: 48%;
		padding: 10px 0;
	}
	div.tickets span.breaky {
		clear: both;
		display: block;
		height: 0px;
	}
}

@media (max-width: 350px) {
	body {
		font-size: 14px;
	}
}
